import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import { ApplicationLayout } from '../application';
import { Form, FormGroup, Label, Input, Table, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormText, FormFeedback } from 'reactstrap'
import formStyles from '../../../components/styles/form.module.css'
import { navigate } from '@reach/router';
import { handleSandboxApplicationCreation, handleProductionApplicationCreation, handleGetApiDetails, handleGetPrivateAllowed } from '../../../services/api';
import { isLoggedIn } from "../../../services/auth";
import validator from 'validator'
import { BarLoader } from 'react-spinners';
import crypto from "crypto";


const user = isLoggedIn() ? JSON.parse(window.localStorage.getItem("gatsbyUser")) : false
var privateallowed = '';

export default class create extends Component {

  componentDidMount() {
    if (!isLoggedIn()) {
      navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
      if (typeof window !== "undefined") {
        window.location.reload()
      }
    }
  }


  render() {
    return (
      <ApplicationLayout>
        <ApplicationForm />
      </ApplicationLayout>
    )
  }
}

const dateTime = Date.now();
const override = JSON.stringify({
  margin: `0 auto`
})
// Main Form
export class ApplicationForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: "",
      api: [],

    }
  }

  handleSelectChange = (event) => {

    let value = event.target.value

    this.setState({
      type: value
    })
  }

  componentDidMount = async () => {
    this.setState({
      isLoading: true
    })

    var apiData = []

    await handleGetApiDetails()
      .then((res) => {
        apiData = res
        this.state.api = res

        this.setState({
          isLoading: false,
          //api : apiData
        })
        console.log(res)
      })
      .catch(e => console.log(e))

    await handleGetPrivateAllowed(user.userData.attributes.email)
      .then((res) => {
        privateallowed = res
        console.log(res)

      })
      .catch(e => console.log(e))


  }

  render() {
    return (
      <Row >
        <Col md={{ size: 6, offset: 3 }} className={formStyles.signupContainer}>
          <Col sm="12" md="12" >
            <Form>
              <h1 style={{ textAlign: "center" }}>Create a new Application</h1>
              <FormGroup>
                <Label>Application Environment</Label>
                {this.state.isLoading ? (
                  <BarLoader
                    sizeUnit={"px"}
                    width={200}
                    color={'#123abc'}
                    css={override}
                  />
                ) :
                  (
                    <Input
                      type="select"
                      id="type"
                      required
                      value={this.state.type}
                      onChange={this.handleSelectChange}
                    >
                      <option value="">- Select Application Environment -</option>
                      <option value="sandbox">Sandbox (For Testing / Development)</option>
                      <option value="production">Production (For Live Transactions)</option>
                      {/* <option value="production">Production (For Actual Use)</option> */}
                    </Input>
                  )}

              </FormGroup>
            </Form>
            {this.state.type === 'sandbox' ? (
              <SandboxForm apiData={this.state.api} />
            ) : this.state.type === 'production' ? (
              <ProductionForm apiData={this.state.api} />
            ) : (
                  `Please select an application environment to proceed`
                )}
          </Col>
        </Col>
      </Row>
    )
  }
}

// Sandbox Form
export class SandboxForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      type: "sandbox",
      rest: false,
      qr: false,
      modal: false,
      username: user ? user.userData.attributes.name : "",
      email: user ? user.userData.attributes.email : "",
      id: user ? user.userData.username : "",
      api: null,
      key: crypto.createHash('sha256').update(user ? user.userData.attributes.name : "").digest('hex'),
      secret: crypto.createHash('sha256').update(user ? user.userData.username : "").digest('hex'),
      typeMessage: "",
      apiname: "",

      nameValid: false,
      typeValid: true,
      apiValid: false,

      formValid: false,
      isLoading: false,

      // API List
      apiData: props.apiData ? props.apiData : [],
      api: "",
    }
    this.toggle = this.toggle.bind(this);

  }

  handleChange = (event) => {
    const name = event.target.id;
    const value = event.target.value;

    this.setState({
      [name]: value
    }, () => {
      this.validateInput(name, value)
    })
  }

  handleApiSelectChange = (event) => {
    const apiName = event.target.id;
    let value = event.target.value
    let apname = event.target.getAttribute('data-apiname')

    this.setState({
      api: value,
      apiname: apname
    }, () => {
      this.validateInput(apiName, value)
    })
  }

  toggleCheckbox(name, event) {
    let obj = {};
    obj[name] = !this.state[name];
    this.setState(obj,
      () => {
        this.validateInput(name)
      });
  }

  validateInput(field, value) {
    let nameValid = this.state.nameValid
    let typeValid = this.state.typeValid
    let apiValid = this.state.apiValid

    switch (field) {
      case 'name':
        nameValid = !validator.isEmpty(value)
        break

      case 'type':
        typeValid = !validator.isEmpty(value)
        break
      case 'api':
        apiValid = !validator.isEmpty(value)
      default:
        break;
    }

    this.setState({
      nameValid: nameValid,
      typeValid: typeValid,
      apiValid: apiValid
    }, () => {
      this.validateForm()
    })
  }

  validateForm() {
    this.setState({
      formValid: this.state.nameValid && this.state.typeValid && this.state.apiValid
    })
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleSubmit = async event => {
    event.preventDefault()
    this.setState({
      isLoading: true
    })
    await handleSandboxApplicationCreation(this.state)
      .then(() => {
        navigate('/account/application/', { state: { notification: "success", message: "Application Creation is successful" } })
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        })
        console.log(err)
      })
  }

  render() {
    return (

      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label>Application Name</Label>
          <Input
            type="text"
            id="name"
            required
            maxLength={60}
            value={this.state.name}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormText>
          <i>
            *Please select at least one API
          </i>
        </FormText>

        <FormGroup>
          <Label>API</Label>
          <Input
            onChange={this.handleApiSelectChange}
            id="api"
            type="select"
            required
          >
            <option value="">- Select API -</option>
            {this.state.apiData.map(function (value, index) {
              var exists = Object.keys(privateallowed).some(function (k) {
                return privateallowed[k].appid == value.id;
              });

              if (value.type === 'OPEN' && value.status === 'ENABLED') {
                return <option value={value.api_id} key={value.api_id} data-apiname={value.sandbox_name ? value.sandbox_name : value.production_name}>{value.sandbox_name ? value.sandbox_name : value.production_name}</option>
              } else if (exists) {
                return <option value={value.api_id} key={value.api_id} data-apiname={value.sandbox_name ? value.sandbox_name : value.production_name}>{value.sandbox_name ? value.sandbox_name : value.production_name}</option>
              }


            }, this)}
          </Input>
        </FormGroup>

        <FormGroup>
          <FormFeedback invalid={this.state.formError}>
            Please select at least 1 (one) API to enable
          </FormFeedback>
        </FormGroup>
        <Button
          block
          color={!this.state.formValid || this.state.isLoading ? 'secondary' : 'primary'}
          disabled={!this.state.formValid || this.state.isLoading}
        >
          CREATE APPLICATION
        </Button>
        <Button color="link" block
          onClick={event => {
            event.preventDefault()
            this.toggle()
          }}
        >
          Cancel
        </Button>

        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Confirmation</ModalHeader>
          <ModalBody>
            Are you sure you want to discard your changes?
            </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={event => {
                navigate('/account/application/')
                this.toggle()
              }}>
              Yes
              </Button>{' '}
            <Button color="secondary" onClick={this.toggle}>No</Button>
          </ModalFooter>
        </Modal>
      </Form>
    )
  }
}

// Production Form
export class ProductionForm extends Component {
  constructor(props) {
    super(props)


    this.state = {
      merchant: user ? user.userData.attributes.name : "",
      organization: user ? user.userData.attributes['custom:organization'] : "",
      country: user ? user.userData.attributes['custom:country'] : "",
      contact: user ? user.userData.attributes['phone_number'] : "",
      name: "",
      type: "production",
      rest: false,
      qr: false,
      modal: false,
      username: user ? user.userData.attributes.name : "",
      email: user ? user.userData.attributes.email : "",
      id: user ? user.userData.username : "",
      api: "",
      key: crypto.createHash('sha256').update(user ? user.userData.attributes.name : "").digest('hex'),
      secret: crypto.createHash('sha256').update(user ? user.userData.username : "").digest('hex'),
      typeMessage: "",
      privateallowed: [],
      apiname: "",

      nameValid: false,
      typeValid: true,
      apiValid: false,

      formValid: false,
      isLoading: false,
      apiData: props.apiData
    }
    this.toggle = this.toggle.bind(this);
  }

  handleChange = (event) => {
    const name = event.target.id;
    const value = event.target.value;

    this.setState({
      [name]: value
    }, () => {
      this.validateInput(name, value)
    })
  }

  handleApiSelectChange = (event) => {
    const apiName = event.target.id;
    let value = event.target.value

    var index = event.target.selectedIndex;
    var optionElement = event.target.childNodes[index]
    let apname = optionElement.getAttribute('data-apiname')
    console.log(apname);

    this.setState({
      api: value,
      apiname: apname
    }, () => {
      this.validateInput(apiName, value)
    })
  }

  validateInput(field, value) {
    let nameValid = this.state.nameValid
    let typeValid = this.state.typeValid
    let apiValid = this.state.apiValid

    switch (field) {
      case 'name':
        nameValid = !validator.isEmpty(value)
        break

      case 'type':
        typeValid = !validator.isEmpty(value)
        break
      case 'api':
        apiValid = !validator.isEmpty(value)
        break
      default:
        break;
    }

    this.setState({
      nameValid: nameValid,
      typeValid: typeValid,
      apiValid: apiValid
    }, () => {
      this.validateForm()
    })
  }

  validateForm() {
    this.setState({
      formValid: this.state.nameValid && this.state.typeValid && this.state.apiValid
    })
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    this.setState({
      isLoading: true
    })

    await handleProductionApplicationCreation(this.state)
      .then(() => {
        navigate('/account/application/', { state: { notification: "success", message: "Application Creation is successful" } })
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        })
        console.log(err)
      })
  }

  render() {
    return (

      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label>Application Name</Label>
          <Input
            type="text"
            id="name"
            required
            maxLength={60}
            value={this.state.name}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup>
          <Label>Requestor Name</Label>
          <Input
            type="text"
            id="merchant"
            required
            maxLength={60}
            readOnly
            defaultValue={this.state.merchant}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup>
          <Label>Company Name</Label>
          <Input
            type="text"
            id="organization"
            required
            maxLength={60}
            readOnly
            defaultValue={this.state.organization}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup>
          <Label>API</Label>
          <Input
            onChange={this.handleApiSelectChange}
            id="api"
            type="select"
            required
          >
            <option value="">- Select API -</option>
            {this.state.apiData.map(function (value, index) {
              var exists = Object.keys(privateallowed).some(function (k) {
                return privateallowed[k].appid == value.id;
              });

              if (value.type === 'OPEN' && value.status === 'ENABLED') {
                return <option value={value.api_id} key={value.api_id} data-apiname={value.production_name ? value.production_name : value.sandbox_name}>{value.production_name ? value.production_name : value.sandbox_name}</option>
              } else if (exists) {
                return <option value={value.api_id} key={value.api_id} data-apiname={value.production_name ? value.production_name : value.sandbox_name}>{value.production_name ? value.production_name : value.sandbox_name}</option>
              }


            }, this)}
          </Input>
        </FormGroup>

        <FormGroup>
          <FormFeedback invalid={this.state.formError}>
            Please select at least 1 (one) API to enable
          </FormFeedback>
        </FormGroup>
        <Button
          block
          color={!this.state.formValid || this.state.isLoading ? 'secondary' : 'primary'}
          disabled={!this.state.formValid || this.state.isLoading}
        >
          CREATE APPLICATION
        </Button>
        <Button color="link" block
          onClick={event => {
            event.preventDefault()
            this.toggle()
          }}
        >
          Cancel
        </Button>

        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Confirmation</ModalHeader>
          <ModalBody>
            Are you sure you want to discard your changes?
            </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={event => {
                navigate('/account/application/')
                this.toggle()
              }}>
              Yes
              </Button>{' '}
            <Button color="secondary" onClick={this.toggle}>No</Button>
          </ModalFooter>
        </Modal>
      </Form>
    )
  }
}
